<script setup lang="ts">
const props = defineProps({
  currentPage: {
    type: Object,
    required: true,
  },
})

const showOptions = ref(false)

const languages = {
  en: 'English',
  es: 'Español',
  pt: 'Português',
}

const currentLanguage = computed(() => {
  return props.currentPage.language_code
    ? languages[props.currentPage.language_code]
    : 'English'
})

function switchLanguage(url) {
  window.location = url
}
</script>

<template>
  <div
    class="relative"
    @click="showOptions = !showOptions"
  >
    <div class="relative flex items-center">
      {{ currentLanguage }}
      <Icon
        name="heroicons:chevron-down-20-solid"
        style="width: 12px; height: 15px"
      />
    </div>
    <ul
      v-show="showOptions"
      class="
        bg-white
        text-gray-400
        absolute
        top-0
        left-0
        z-50
        text-left
        mt-8
        md:mt-4
      "
    >
      <li
        v-for="language in currentPage.localizations"
        :key="language.id"
        class="border-b border-gray-300"
        @click.prevent="switchLanguage(language.path)"
      >
        <a
          href=""
          class="
            p-2
            pr-10
            pl-4
            block
            text-gray-500
            hover:bg-glueblue-300
            hover:text-gray-800
            tranform-colors
            duration-150
          "
        >{{ languages[language.language_code] }}</a>
      </li>
    </ul>
  </div>
</template>
